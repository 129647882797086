import moment from "moment"
import { Link } from "react-router-dom"

const levelChangeColumns = () => [
  {
    dataField: "createdAt",
    text: "Date",
    formatter: (cellContent, order) => (
      <p className="mb-0">
        {moment(order?.createdAt).format("YYYY-MM-DD HH:mm")}
      </p>
    ),
  },
  {
    dataField: "type",
    text: "Change Type",
  },
  {
    dataField: "sportType",
    text: "Sport Type",
    formatter: (cellContent, order) => (
      <div>
        <p className="mb-0">{order.body?.sportType || 'tennis'}</p>
      </div>
    ),
  },
  {
    dataField: "gameLevel",
    text: "Level",
    formatter: (cellContent, order) => (
      <div>
        <p className="mb-0">{order?.body?.gameLevel?.singles || order?.body?.gameLevelSingle}</p>
        <p className="mb-0">{order?.body?.gameLevel?.doubles || order?.body?.gameLevelDouble}</p>
      </div>
    ),
  },
  {
    dataField: "for",
    text: "For",
    formatter: (cellContent, order) => (
      <div>
        <p className="mb-0">Single</p>
        <p className="mb-0">Double</p>
      </div>
    ),
  },
  {
    dataField: "admin",
    text: "Owner",
    formatter: (cellContent, order) => (
      <p className="mb-0">
        <Link to={`/accounts/admins/${cellContent?.id}`}>
          {" "}
          {cellContent?.firstName} {cellContent?.lastName}
          {" - "}
          {cellContent?.userId}
        </Link>
      </p>
    ),
  },
]

export default levelChangeColumns
